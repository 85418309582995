const Header = ({}) => {

    return (
      <div className="header">
        <div className="header-logo"><h1>Legendary Chainsaw</h1></div>
        <div className="menu-buttons">
            <button className="pantera" id="loginWithAmazon">login</button>
        </div>
      </div> 
    );
   
}
export default Header;