import React, { useState } from 'react';

const SideBar = () => {
  
    return (
      <div className="sideBar">
          <h1>Side bar</h1>
          <div id="lwaTokenInfo">
            <body>No token currently present</body>
          </div> 
      </div> 
    );
    
}
// ========================================
  
export default SideBar;